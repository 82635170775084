<template>
    <v-app>
      <v-main>
      <v-app-bar app>
        Tomlico
        <v-spacer/>
        Logged in: {{userName}}
        <amplify-sign-out v-if="isLoggedIn"></amplify-sign-out>
      </v-app-bar>
      <amplify-authenticator
          header-text="Welcome to tomlico.com. Please sign in"
          hide-sign-up="false">
      <router-view ></router-view>
      
      </amplify-authenticator>
      <v-footer>
        Copyright 2020
        </v-footer>
      </v-main>
    </v-app>
</template>

<script>
import Amplify from 'aws-amplify';
import store from './store/store';
export default {
  name: 'App',
  data: () => ({
      isLoggedIn: false
  }),
  computed: {  
    userName: function(){
      if(this.isLoggedIn != null){
        return this.$store.state.userInfo.username;
      } else {
        return ''
      }
      
    }
    // ,
    // isLoggedIn: function(){
    //   console.log(this.$store.state.userInfo);
    //   if(this.$store.state.userInfo == ''){
    //     return false
    //   } else {
    //     return true
    //   }
    // }
  },
  methods: {
    showLoggedInUser: function() {
    }
  },  
  async mounted() {
    const currentUser = await Amplify.Auth.currentUserInfo();
    if (currentUser != null){
      this.isLoggedIn = true;
      this.$store.commit('saveUserInfo',currentUser);
    }   
  }
};
</script>
