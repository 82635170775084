import Vue from 'vue';
import App from './App.vue';
import '@aws-amplify/ui-vue';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import router from './router';
import vuetify from './plugins/vuetify';
import Vuex from 'vuex';
import store from './store/store';
Vue.use(Vuex)

Amplify.configure(awsconfig);

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store: store,
  render: h => h(App)
}).$mount('#app')
