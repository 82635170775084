/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:b9d487d5-1d8b-443f-8dbc-a2d05559af75",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_xvUyFUSum",
    "aws_user_pools_web_client_id": "74iojc27t2vl41i8cl6no4821o",
    "oauth": {}
};


export default awsmobile;
