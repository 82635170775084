<template>
    <v-app>
        <v-layout>
            <v-card>
                Test card
            </v-card>
        </v-layout>
    </v-app>
</template>

<script>
export default {
 computed: {
    //  users() {
    //      return this.$store.user;
    //  }
 }
}
</script>