<template>
    <div>
      This is the Home page
      <!-- <v-app>
        <v-main>
          <v-app-bar app>
            This is Tomlico.com
            <v-spacer/>
            Logged in:  {{userInfoUserName}}
          </v-app-bar>

      
        </v-main>
      </v-app> -->
    </div>
</template>

<script>
export default {
  name: 'Home'
  ,data() {
    return {
      
    }
  },
  computed: {
    userInfoUserName: function() {
      return this.$store.state.userInfo.username;
    }
  }
}
</script>
