<template>
    <div id="app">
      <v-app>
          <v-card>
              <v-card-title>
                  <reg></reg>
                  {{ count }}
              </v-card-title>
          </v-card>
      </v-app>
    </div>
</template>

<script>
import reg from '../components/register.vue';
import store from '../store/store';
export default {
  name: 'Register'
  ,store
  ,components: {
      reg
  },
   data () {
    return {
      count: store.state.count
    }
  }
}
</script>
